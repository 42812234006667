import {IPolicy, IPremium} from '../models';
import { getIncludedResource } from '../util';
import * as document from './documents';
import {mapTraveller} from './application';
import * as paymentMethod from './activePaymentMethods';
import { get } from 'lodash';
import { format, isValid, parse } from 'date-fns';

const dateFromApi = (date) => {
  if (! date) {
    return '';
  }

  const parsedDate = new Date(date);

  return isValid(parsedDate) ? format(parsedDate, 'dd/MM/yyyy') : '';
};

export function fromJsonApi(data, included: any[]): IPolicy {
  const documents = getIncludedResource(data, included, 'documents', true)
    .map((item) => document.fromJsonApi(item, included));

  const premiums: IPremium[] = getIncludedResource(data, included, 'premiums', true).map((premium): IPremium => {
    const scheme = getIncludedResource(premium, included, 'scheme');
    return {
      id: premium.id,
      schemeType: premium.attributes.metadata.scheme.raw_scheme_type,
      calculatedAt: premium.attributes.metadata.calculated_at,
      validUntil: premium.attributes.metadata.valid_until,
      currency: premium.attributes.currency,
      tax: premium.attributes.tax,
      exTax: premium.attributes.exTax, // I think this should be ex_tax
      principal: premium.attributes.principal,
      grossExAdditional: premium.attributes.gross_ex_additional,
      gross: premium.attributes.gross,
      discount: premium.attributes.discount,
      taxRate: premium.attributes.tax_rate,
      benefits: premium.attributes.metadata.benefits.map((benefit) => ({
        name: benefit.name,
        text: benefit.text,
        description: benefit.description,
        inverse: benefit.inverse,
        excess: benefit.excess,
        displayName: benefit.display_name,
        deprecation: benefit.deprecation || null,
        shortName: benefit.short_name,
        subBenefits: benefit.sub_benefits && benefit.sub_benefits.map((subBenefit) => ({
          excess: subBenefit.excess,
          name: subBenefit.name,
          text: subBenefit.text,
          description: subBenefit.description,
        })),
      })),
      information: premium.attributes.metadata.information && {
        standardExcess: premium.attributes.metadata.information.standard_excess,
        hasGroups: premium.attributes.metadata.information.has_groups || false,
        boltOns: premium.attributes.metadata.information.bolt_ons,
        upgrade: premium.attributes.metadata.information.upgrade && {
          schemeId: premium.attributes.metadata.information.upgrade.scheme,
          amount: premium.attributes.metadata.information.upgrade.amount,
          coverLevel: premium.attributes.metadata.information.upgrade.coverLevel,
        },
        maxTripDuration: premium.attributes.metadata.information.max_trip_duration,
        startDate: premium.attributes.metadata.information.start_date,
        region: premium.attributes.metadata.information.region,
        tripType: premium.attributes.metadata.information.scheme_type,
        fraudCheck: premium.attributes.metadata.information.fraud_check || false,
        showRewardsBanner: premium.attributes.metadata.information.show_rewards_banner || false,
        upSellIncreaseRatio: premium.attributes.metadata.information.up_sell_increase_ratio || undefined,
        cprResponse: premium.attributes.metadata.information.cpr_response || undefined,
        timeOfQuote: premium.attributes.metadata.information.time_of_quote || null,
        mods: premium.attributes.metadata.information.mods || null,
        controlGroup: premium.attributes.metadata.information.control_group || null,
      },
      declines: premium.attributes.metadata.declines,
      referrals: premium.attributes.metadata.referrals,
      errors: premium.attributes.metadata.errors && premium.attributes.metadata.errors,
      scheme: {
        id: scheme.id,
        coverLevel: scheme.attributes.cover_level,
        status: scheme.attributes.status,
        logo: scheme.attributes.logo,
        name: scheme.attributes.name,
        insurer: scheme.attributes.insurer,
        documents: premium.attributes.metadata.scheme.documents,
        includedOptions: scheme.attributes.metadata.included_options || [],
        allBenefits: premium.attributes.metadata.benefits.map((benefit) => ({
          name: benefit.name,
          text: benefit.text,
          description: benefit.description,
          inverse: benefit.inverse,
          excess: benefit.excess,
          displayName: benefit.display_name,
          deprecation: benefit.deprecation || null,
          shortName: benefit.short_name,
          subBenefits: benefit.sub_benefits && benefit.sub_benefits.map((subBenefit) => ({
            excess: subBenefit.excess,
            name: subBenefit.name,
            text: subBenefit.text,
          })),
        })),
      },
      premiumBeforeAdjustment: premium.attributes.premium_before_adjustment && {
        currency: premium.attributes.premium_before_adjustment.currency,
        principal: premium.attributes.premium_before_adjustment.principal,
        medical: premium.attributes.premium_before_adjustment.medical,
        tax: premium.attributes.premium_before_adjustment.tax,
        gross: premium.attributes.premium_before_adjustment.gross,
        exTax: premium.attributes.premium_before_adjustment.ex_tax,
      },
    };
  });

  const travellers = [];
  if (data.attributes.metadata.adults) {
    data.attributes.metadata.adults.forEach((adult, key) => {
      travellers.push(mapTraveller('adult', adult, key));
    });
  }

  if (data.attributes.metadata.children) {
    data.attributes.metadata.children.forEach((child, key) => {
      travellers.push(mapTraveller('child', child, key));
    });
  }

  let policyStatus = data.attributes.status;
  const isAnnual = (data.attributes.metadata.scheme_type || '') === 'annual';
  if (isAnnual && policyStatus === 'issued') {
    policyStatus = 'Purchased';
  }

  const activePaymentMethod = paymentMethod.fromJsonApi(data);
  const assignedPaymentMethods = paymentMethod.paymentMethodsOnPolicy(data)

  return {
    policyNumber: data.attributes.policy_number,
    id: data.id,
    insured: data.attributes.insured,
    status: policyStatus,
    version: data.attributes.version,
    productName: data.attributes.product_name,
    userName: data.attributes.user_name,
    brokerName: data.attributes.broker_name,
    channelName: data.attributes.channel_name,
    minimumDocumentCount: data.attributes.minimum_document_count,
    isInsideCancellationPeriod: data.attributes.is_inside_cancellation_period,
    metadata: data.attributes.metadata,
    commencesAt: dateFromApi(data.attributes.commences_at),
    expiresAt: dateFromApi(data.attributes.expires_at),
    issuedAt: data.attributes.issued_at,
    cancelledAt: data.attributes.cancelled_at,
    totalPremiumValue: data.attributes.total_premium_value,
    hasRenewal: data.attributes.has_renewal,
    termNumber: get(data, 'attributes.term_number'),
    documents,
    renewalStatus: data.attributes.renewal_status,
    premiums,
    travellers,
    activePaymentMethod,
    assignedPaymentMethods
  };
}
