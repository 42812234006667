export const isoFormat = 'yyyy-MM-dd\'T\'HH:mm:ssxxx'

/**
 * Takes a Date object, and removes the timezone whilst maintaining the same time.
 * e.g. Date object of: 2025-01-01T12:34:56+02:00 becomes
 * Date object of: 2025-01-01T12:34:56Z
 *
 * This replaces our usage of the moment function utcOffset(0, true)
 */
export function stripTimezone(date: Date): Date {
  const year = date.getUTCFullYear();
  const month = date.getUTCMonth();
  const day = date.getUTCDate();
  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  const seconds = date.getUTCSeconds();
  const milliseconds = date.getUTCMilliseconds();

  return new Date(Date.UTC(year, month, day, hours, minutes, seconds, milliseconds));
}
