import * as React from 'react';
import DatePicker from 'react-datepicker';
import { isValid, format } from 'date-fns';
import './style.scss';
import { enGB } from 'date-fns/locale';


const DATE_FORMAT = 'dd/MM/yyyy';

const AlternativeDatePicker = ({ input, change, placeholder, meta: { touched, error }, ...props }) => {
  const handleChange = (date) => {
    const dateObj = new Date(date);

    input.onChange(isValid(dateObj) ? format(dateObj, DATE_FORMAT) : '');
  };

  return (
    <div className={touched && error ? 'has-error' : ''}>
      <div className="icon-cal">
        <DatePicker
          {...input}
          {...props}
          locale={enGB}
          customInput={<img alt={''} src={require('./image/icon-cal.svg')}/>}
          placeholder={placeholder}
          dateFormat={DATE_FORMAT}
          onChange={handleChange}
          disabledKeyboardNavigation={false}
        />
      </div>
      {/*{touched && error && <HelpBlock>{error}</HelpBlock>}*/}
    </div>
  );
};

export default AlternativeDatePicker;
