import { IPolicyApplication, IStep } from '../data/api/models';
import { find, findIndex, forEach, get, isEmpty } from 'lodash';
import { differenceInDays, parse } from 'date-fns';

export default class DataLayer {
  public static push(event = null): void {
    if (window.dataLayer && event) {
      window.dataLayer.push(event);
    }
  }

  public static tierUpgrade = (premiums: any, currentSchemeId: string, selectedScheme: any) => {
    const tiers = ['admiral', 'gold', 'platinum']
    const currentScheme = get(find(premiums, ['scheme.id', currentSchemeId]), 'scheme.coverLevel', '')
    const newScheme = get(selectedScheme, 'scheme.coverLevel', '')
    const currentIndex = findIndex(tiers, (tier, index) => {
      return tier === currentScheme.toLowerCase()
    }, 0) || 0;
    const selectedIndex = findIndex(tiers, (tier, index) => {
      return tier === newScheme.toLowerCase()
    }, 0) || 0;

    if (currentIndex < selectedIndex) {
      DataLayer.push({ 'TierUpgrade': 'true' })
    }

  }

  public static handleTrackPage = (nextStep: IStep, currentStep: IStep, formValues: IPolicyApplication) => {
    const schemeId = get(formValues, 'schemeId');
    const premiums = get(formValues, 'premiums');
    const selectedScheme = find(premiums, ['scheme.id', schemeId])
    const startDate = get(formValues, 'startDate');
    const endDate = get(formValues, 'endDate')
    const isSingle = get(formValues, 'tripType', '') === 'single'
    const maxTripDuration = get(formValues, 'maxTripDuration');
    let tripLength = null;
    let additionalOptions = '';
    let data = null;
    let hasScreening = false;
    let medicalConditions = 0;

    if (isSingle && startDate && endDate) {
      tripLength = differenceInDays(
        parse(endDate, 'dd/MM/yyyy', new Date()),
        parse(startDate, 'dd/MM/yyyy', new Date())
      )
    }else if (!isSingle && startDate) {
      tripLength = maxTripDuration;
    }

    if (get(formValues, 'options.winter_sports')) {
      additionalOptions += 'Winter Sports '
    }

    if (get(formValues, 'options.cruise_cover')) {
      additionalOptions += 'Cruise Cover '
    }

    if (get(formValues, 'options.gadget_cover')) {
      additionalOptions += 'Gadget Cover '
    }

    if (get(formValues, 'options.excess_waiver')) {
      additionalOptions += 'Excess Waiver'
    }

    forEach(get(formValues, 'travellers'), (traveller) => {
      if (traveller.screening) {
        hasScreening = true;
      }
      if (get(traveller, 'screening.meta.conditions', []).length > 0) {
        medicalConditions += get(traveller, 'screening.meta.conditions', []).length
      }
    });

    switch (nextStep.key) {
      case 'tripDeclaration':
      case 'tripDetails':
        break;
      case 'travellersDetails':
        data = {
          'startDate': get(formValues, 'startDate', undefined),
          'endDate': get(formValues, 'endDate', undefined),
          'region': get(formValues, 'region', undefined),
          'destination': !isEmpty(get(formValues, 'destinations', [])) ? get(formValues, 'destinations').join(', ') : undefined,
          'tripType': get(formValues, 'tripType', undefined),
          'cruise': get(formValues, 'options.cruise_cover', false) ? 'Y' : 'N',
          'winter': get(formValues, 'options.winter_sports', false) ? 'Y' : 'N',
          'tripLength': tripLength
        }
        break;
      case 'medicalDeclaration':
        data = {
          'policyType': get(formValues, 'groupType', undefined),
          'voucherCode': get(formValues, 'campaignCode', undefined),
          'medicalConditionsDeclared': medicalConditions,
          'tripLength': tripLength
        }
        break;
      case 'schemeChoice':
        data = {
          'quoteReferenceNumber': get(formValues, 'quoteReference', undefined),
          'medicalConditionsDeclared': medicalConditions,
          'medical': hasScreening ? 'Y' : 'N',
        }
        break;
      case 'signpost':
      case 'additionalCover':
        data = {
          'tier': get(selectedScheme, 'scheme.coverLevel'),
          'tripLength': tripLength
        }
        break;
      case 'payment':
        data = {
          'optionalExtras': additionalOptions
        }
        break;
    }

    DataLayer.push(data)

  }
}
