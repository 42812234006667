import * as React from 'react';
import { difference, filter, find, flow, forEach, get, head, includes, isEmpty, keys, map, some, values } from 'lodash';
import { IBrandingState, II18nMessages } from '../../../../services/branding/models';
import { Button, Col, ControlLabel, Form, FormGroup, Modal, OverlayTrigger, Popover, Row } from 'react-bootstrap';
import { Field, formValueSelector, InjectedFormProps, reduxForm, submit } from 'redux-form';
import { connect } from 'react-redux';
import SelectControl from '../../../../components/SelectControl';
import AlternativeDatePicker from '../../../../components/AlternativeDatePicker/index';
import { bindActionCreators } from 'redux';
import { recalculateScore } from '../../../../services/verisk/reducer';
import { push } from 'react-router-redux';
import * as Commonmark from 'react-commonmark';
import { actions as quoteActions } from '../../../../services/quote/reducer';
import { IStore } from '../../../../redux/IStore';
import { IQuoteActions, IQuoteState } from '../../../../services/quote/models';
import FieldFormControl from '../../../../components/FieldFormControl';
import Loading from '../../../../components/Loading';
import { IChannel, IPolicyApplication, IPremium, IProduct, ITraveller } from '../../../../data/api/models';
import { FormattedNumber } from 'react-intl';
import './style.scss';
import { addDays, differenceInDays, format, parse } from 'date-fns';
import { FaInfoCircle, FaQuestionCircle } from 'react-icons/fa';
import { IAuthState } from '../../../../services/auth/models';
import DynamicTextWithHelp from '../AdditionalCover/components/QuestionOptions/DynamicTextWithHelp';
import BusinessContinuityPlan from '../../../../helpers/BusinessContinuityPlan';
import { annualLimit, isRequired, validateFutureDateOnFinish } from '../../../../util/validators';
import Signposting, { SignpostType } from '../../components/Signposting';
import Quotes from './components/Quotes';
import { quoteHasMedical } from '../../../../helpers/signpost';
import DataLayer from '../../../../helpers/DataLayer';
import { RemoveUkRegion } from '../../../../helpers/RemoveUkRegion';

interface ISchemeChoiceFormProps extends InjectedFormProps {
  i18n: II18nMessages;
  quote: IQuoteState;
  application: IPolicyApplication;
  travellers: ITraveller[];
  tripType: string;
  schemeId: string;
  startDate: string;
  password: string;
  passwordConfirmation: string;
  premiums: IPremium[];
  auth: IAuthState;
  endDate: string;
  pristine: boolean;
  submitting: boolean;
  dispatch: any;
  channel: IChannel;
  options: any;
  signposted: any;
  isUpsell: boolean;
  onBack: () => void;

  renderDefinition(name: string): any;

  recalculateScore(callback, screeningId, region, destinations);

  product: IProduct;
  quoteType: string;
  actions: {
    submit(name: string);
    push: (route: string) => void;
    quote: IQuoteActions;
  };
  branding?: IBrandingState;
  timeOfQuote?: string;
  maxTripDuration?: any;
  mods?: any[];
  quoteForEdit?: boolean;
}

interface ISchemeChoiceFormState {
  moreInfo: boolean;
  showUpSellModal: boolean;
  isQuoteSaved: boolean;
  isSchemePresent: boolean;
}

class SchemeChoiceForm extends React.Component<ISchemeChoiceFormProps, ISchemeChoiceFormState> {
  public state = {
    moreInfo: false,
    showUpSellModal: false,
    isQuoteSaved: false,
    isSchemePresent: true,
  };

  constructor(props) {
    super(props);

    const { quote, dispatch, change, travellers } = props;
    const isSignposted = get(quote, 'selectedPremium.isSignposted', false);
    const hasMedicalAdded = quoteHasMedical(null, travellers);
    dispatch(change('signposted', {
      medicalDecline: false,
      medicalLoad: isSignposted && hasMedicalAdded,
    }));
    dispatch(change('isUpsell', false));
  }

  public componentWillMount() {
    const { actions, dispatch, change, tripType, premiums, schemeId } = this.props;

    // When the scheme scheme page loads we should try to create a user account and send them a quote email
    Promise.all([
      Promise.resolve(dispatch(change('calculateAllSchemes', true))),
    ]).then(() => {
      if (tripType === 'single') {
        Promise.resolve(dispatch(change('upSellIncreaseRatio', null))).then(() => {
          actions.quote.calculate(null, true).then(() => {
            Promise.resolve(dispatch(change('calculateAllSchemes', false)));
            Promise.resolve((dispatch(change('generateDocuments', false))));
            Promise.resolve(dispatch(change('emailSend', false)));
            Promise.resolve(dispatch(change('calculateAllSchemes', false)));
          });
        });
      } else {
        actions.quote.calculate(null, true).then(() => {
          Promise.resolve(dispatch(change('calculateAllSchemes', false)));
          Promise.resolve((dispatch(change('generateDocuments', false))));
          Promise.resolve(dispatch(change('emailSend', false)));
          Promise.resolve(dispatch(change('calculateAllSchemes', false)));
        });
      }
      if (schemeId) {
        const selectedPremium = find(premiums, ['scheme.id', schemeId])
        if (selectedPremium) {
          this.addSignposted(selectedPremium)
        }
      }
    });
  }

  private handleSubmitUpsell = () => {
    const { change, dispatch, actions, schemeId } = this.props;
    // Submit the form only after every change has been applied
    Promise.all([
      Promise.resolve(dispatch(change('endDate', ''))),
      Promise.resolve(dispatch(change('destinations', []))),
      Promise.resolve(dispatch(change('tripType', 'annual'))),
    ]).then(() => {
      this.setState({
        showUpSellModal: false,
      });
      Promise.resolve(dispatch(change(`upsellDate`, format(new Date(), 'yyyy-MM-dd HH:mm:ss')))).then(() => {
        Promise.resolve(actions.quote.saveQuote(true)).then((quote: IPolicyApplication) => {
          const premium = find(quote?.premiums, ['scheme.id', schemeId])
          Promise.resolve(dispatch(change('isUpsell', true))).then(() => {
            this.addSignposted(premium)
            actions.submit('quote');
          });
          DataLayer.push({ 'UpgradeToAmt': 'true' })
        });
      });
    });
  }

  private checkForUpSell = () => {
    const { quote, schemeId, actions, tripType, maxTripDuration, change } = this.props;

    if (!schemeId) {
      this.setState({
        isSchemePresent: false,
      });
      window.scrollTo(0, 0);
    }

    forEach(quote.quotes, (quote) => {
      const annualQuote = quote['Annual Multi Trip'];
      const singleQuote = quote['Single Trip'];

      if (tripType === 'single' && schemeId === annualQuote.scheme.id) {
        change('region', annualQuote.information.region);
        change('maxTripDuration', 31);

        this.setState({
          showUpSellModal: true,
        });
      }

      if ((singleQuote && schemeId === singleQuote.scheme.id) || tripType === 'annual') {
        actions.submit('quote');
      }
    });
  }

  private renderOption = (option, key) => {
    const { quote, startDate, endDate, product, channel, options } = this.props;
    const quotes = quote.quotes;

    if (isEmpty(quotes)) {
      return null;
    }

    const coverLevel = keys(quotes)[0];
    const tripType = keys(quotes[coverLevel])[0];
    const quotedCover = quotes[coverLevel][tripType];
    // const includedOptions = quotedCover.scheme.includedOptions;

    // TODO likely to include gadget and golf options all tiers so unsure if this will be relevant
    // const isIncludedOption = includes(includedOptions, key);
    const boltOns = quotedCover.information ? quotedCover.information.boltOns : null;
    // const currency = quotedCover.currency;
    const boltOn = boltOns ? boltOns[key] : null;
    const boltOnTotal = boltOn ? boltOn.total : 0;
    // const start = moment(startDate, 'DD/MM/YYYY');
    // const end = moment(endDate, 'DD/MM/YYYY');
    // const singleTripDuration = end.diff(start, 'days');

    const channelType = channel.channelType;
    const aggregatorChannel = channelType === 'AGG';
    const isAggregatorCruiseCover = key === 'aggregator_cruise_cover';
    const hasAggregatorCruiseCoverBenefit = includes(keys(boltOns), 'aggregator_cruise_cover');
    const isCruiseCover = key === 'cruise_cover';
    const isWinterSports = key === 'winter_sports';
    // const isGolfCover = key === 'golf_cover';
    // const isGadgetCover = key === 'gadget_cover';

    if (!['cruise_cover', 'winter_sports'].includes(key)) {
      return;
    }

    const popOverText = product.metadata.definitions[`${key}_alt_help`];
    const dynamicText = product.metadata.definitions[`${key}_alt_dynamic`];
    const title = product.metadata.definitions[`${key}_alt_title`];

    if (boltOnTotal === 0) {
      return null;
    }

    if (!aggregatorChannel && isAggregatorCruiseCover) {
      return null;
    }

    if (
      aggregatorChannel
      && (
        (hasAggregatorCruiseCoverBenefit && isCruiseCover)
        || (!hasAggregatorCruiseCoverBenefit && isAggregatorCruiseCover)
      )
    ) {
      return null;
    }

    if (!quotedCover.information) {
      return null;
    }

    if (isWinterSports) {
      const hasWinterSportsExclusion = some(quote.application.travellers, (traveller) => {
        return traveller.screening && traveller.screening.meta.hasWinterSportsExclusion;
      });

      if (hasWinterSportsExclusion) {
        return <p>Sorry we are unable to provide you for a quote for Winter Sports Cover.</p>;
      }
    }

    return (
      <DynamicTextWithHelp popOverText={popOverText}
                           title={title}
                           dynamicText={dynamicText}
                           name={`options.${key}`} key={key}
                           active={options && options[`${key}`]}/>
    );
  }

  private closeUpSellModal = () => {
    const { change, startDate, endDate } = this.props;
    const start = parse(startDate, 'dd/MM/yyyy', new Date());
    const end = parse(endDate, 'dd/MM/yyyy', new Date());
    change('maxTripDuration', differenceInDays(end, start));
    this.setState({
      showUpSellModal: false,
    });
  }

  private checkHasProperty = (quote, check) => quote && quote[check] && quote[check].length > 0;

  private renderUpSellModal = () => {
    const { i18n, product, valid, premiums, quoteForEdit } = this.props;

    const annualTripDuration = map(get(product, 'metadata.annual_trip_durations', {}), (name, key) => {
      return {
        label: name,
        value: parseInt(key, 10),
      };
    });

    const hideUKRegion = get(head(premiums), 'information.hideUkRegion', false)
    const productRegions = RemoveUkRegion(product.metadata.regions, quoteForEdit, hideUKRegion)
    const hasUKRegion = get(productRegions, 'uk', null) !== null;

    const popoverClickRootClose = (
      <Popover id="popover-trigger-click-root-close" title="Regions">
        {hasUKRegion && <Commonmark source={product.metadata.definitions.region_uk}/>}
        <Commonmark source={product.metadata.definitions.region_europe}/>
        <Commonmark source={product.metadata.definitions.region_worldwide_excluding}/>
        <Commonmark source={product.metadata.definitions.region_worldwide}/>
      </Popover>
    );

    const popoverClickRootCloseTripExtension = (
      <Popover id="popover-trigger-click-root-close-trip-extension" title="TripExtension">
        <Commonmark
          source={'Annual Multi-Trip policies cover multiple trips of up to 31 days per trip as standard. You can increase the maximum trip duration for the policy term, either up to 45 days or up to 60 days per trip.'}/>
      </Popover>
    );

    const regionsList = map(productRegions, (name, key) => ({
      label: name,
      value: key,
    }));

    return (
      <Modal show={this.state.showUpSellModal} onHide={this.closeUpSellModal}>
        <Modal.Header closeButton={false}>
          <Modal.Title>Upgrading to Annual Multi-Trip</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="section-group">
            <div className="section">
              Please confirm the region that you require cover for.
              <p>
                If your selected region differs from the region of your original
                destination, this may affect the premium:
              </p>
              <Row className="margin-top">
                <FormGroup controlId="destination">
                  <Col componentClass={ControlLabel} sm={3}>
                    {i18n.quote.tripDetails.region}{' '}
                    <OverlayTrigger
                      trigger="click"
                      rootClose={true}
                      placement="right"
                      overlay={popoverClickRootClose}
                    >
                      <FaInfoCircle/>
                    </OverlayTrigger>
                  </Col>
                  <Col sm={9} className="destinations-inputs">
                    <Field
                      name="region"
                      type="text"
                      component={SelectControl}
                      options={regionsList}
                      validate={[
                        isRequired,
                      ]}
                    />
                  </Col>
                </FormGroup>
              </Row>
            </div>
            <div className="section">
              <Row>
                <FormGroup controlId="start-date">
                  <Col componentClass={ControlLabel} xs={6}>
                    Start Date
                  </Col>
                  <Col sm={6}>
                    <div className="alternative-date-picker">
                      <div className="field">
                        <Field
                          name="startDate"
                          type="masked-date"
                          placeholder="dd/mm/yyyy"
                          component={FieldFormControl}
                        />
                      </div>
                      <div className="icon">
                        <Field
                          name="startDate"
                          maxDate={addDays(new Date(), 180)}
                          component={AlternativeDatePicker}
                          minDate={new Date()}
                          validate={[isRequired, validateFutureDateOnFinish, annualLimit]}
                        />
                      </div>
                    </div>
                  </Col>
                </FormGroup>
              </Row>
              <div className="info-box">
                <strong>IMPORTANT</strong> - {i18n.quote.tripDetails.coverWarning}
              </div>
            </div>
            <div className="section">
              Please confirm the individual trip length.
              <p>
                If you increase your max trip duration to 45 or 60 days, this will affect the premium:
              </p>
              <Row className="margin-top">
                <div>
                  <FormGroup controlId="tripExtension">
                    <Col componentClass={ControlLabel} sm={5}>
                      {'Individual Trip length'}
                      <OverlayTrigger
                        trigger="click"
                        rootClose={true}
                        placement="right"
                        overlay={popoverClickRootCloseTripExtension}
                      ><FaQuestionCircle className="info-btn"/>
                      </OverlayTrigger>
                    </Col>
                    <Col sm={7} className="destinations-inputs">
                      <Field
                        name="maxTripDuration"
                        id="trip-duration-inputs"
                        type="text"
                        component={SelectControl}
                        forceFilter={false}
                        options={annualTripDuration}
                        validate={[
                          isRequired,
                        ]}
                      /></Col>
                  </FormGroup>
                </div>
              </Row>
            </div>
            <div className="section">
              {map(product.metadata.options, this.renderOption)}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            bsStyle="primary"
            bsSize="lg"
            id="continue"
            disabled={!valid}
            onClick={this.handleSubmitUpsell}
          >
            CONTINUE
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  private toggleMoreInfo = () => {
    this.setState((prevState) => ({
      moreInfo: !prevState.moreInfo,
    }));
  }

  private onAnnualSchemeSelected = (selected) => {
    this.onSchemeSelect(selected);
  }

  private onSingleSchemeSelected = (selected) => {
    this.onSchemeSelect(selected);
  }

  private onSchemeSelect = (selected) => {
    const { schemeId, premiums, actions } = this.props;
    DataLayer.tierUpgrade(premiums, schemeId, selected)
    this.setState({ isSchemePresent: true });
    actions.quote.setSelectedPremium(selected);
    this.addSignposted(selected);
    this.addToMetadata(selected);
  }

  private addToMetadata = (selectedPremium) => {
    const { timeOfQuote, mods, change, quote, quoteType } = this.props;
    const timeOfQuoteFromPremiumInfo = get(selectedPremium, 'information.timeOfQuote');
    const modsFromPremiumInfo = get(selectedPremium, 'information.mods');
    const isNewBusiness = quoteType === 'new business'

    change('upSellIncreaseRatio', quote.upSellIncreaseRatio);
    change('cprResponse', quote.cprResponse);

    if (!timeOfQuote && timeOfQuoteFromPremiumInfo && isNewBusiness) {
      change('timeOfQuote', timeOfQuoteFromPremiumInfo);
    }

    if (!mods && modsFromPremiumInfo && isNewBusiness) {
      change('mods', modsFromPremiumInfo);
    }

  }

  private addSignposted = (selected = null) => {
    const { dispatch, change, travellers } = this.props;
    if (!selected) {
      return;
    }
    const hasMedicalAdded = quoteHasMedical(null, travellers);
    dispatch(change('signposted', {
      medicalDecline: false,
      medicalLoad: get(selected, 'isSignposted', false) && hasMedicalAdded,
    }));
  }

  private renderAnnualQuotes = () => {
    const { quote, schemeId, product } = this.props;
    const quotes = quote.quotes;
    const annual = 'Annual Multi Trip';

    const orderedQuotes = map(product.metadata.scheme_order, (order: string) => {
      return quotes[order];
    });

    const annualQuotes = map(orderedQuotes[0] && orderedQuotes, (tripTypes, level) => {
      return tripTypes[annual]
    })

    return (
      <div className={'quote-container'}>
        <div className="quote-body">

          <Row className="quote-header-container">
            {map(annualQuotes, (quote, index) => {
              return (
                <Col xs={4}>
                  <div className="quote-header">
                    {get(quote, 'scheme.coverLevel')}
                  </div>
                </Col>
              )
            })}
          </Row>

          <Row className="quote-select-annual-container">
            {map(orderedQuotes[0] && orderedQuotes, (tripTypes, level) => {
              const single = keys(tripTypes).length > 1 && 'Single Trip';
              const difference = (keys(tripTypes).length > 1 ? tripTypes[annual].gross - tripTypes[single].gross : 0);
              const tripType = tripTypes[annual];
              const annualScheme = tripTypes[annual].scheme;

              return (
                <Col xs={4} className={`button-wrapper-${level}`}>
                  <div style={{ padding: '0 15px' }}>
                    <Field
                      name="schemeId"
                      type="radio"
                      value={annualScheme.id}
                      className="select-quote"
                      component={FieldFormControl}
                      validate={[isRequired]}
                      onChange={this.onAnnualSchemeSelected.bind(this, tripType)}
                    >
                      <div className="select-quote-btn">
                        {annualScheme.id === schemeId ? 'Upgraded' : 'Upgrade +'}
                        {annualScheme.id === schemeId ? '' : (
                          <FormattedNumber
                            value={difference}
                            style="currency"
                            currency={tripTypes[annual].currency}
                          />
                        )}
                      </div>
                    </Field>
                  </div>
                </Col>
              )
            })}
          </Row>

          <Row className="quote-documents-container">
            {map(annualQuotes, (quote, index) => {
              const documents = get(quote, 'scheme.documents');
              return (
                <Col xs={4} className={`document-wrapper-${index}`}>
                  <div className="quote-documents">
                    {map(documents, (documentUrl: string, documentName: string) => {
                      if (documentName === 'Insurance Product Information Document') {
                        return (
                          <div style={{ padding: '0 15px 0 15px' }}>
                            <a className="btn btn-documents" href={documentUrl} target="_blank" key={documentName}>
                              {documentName}
                            </a>
                          </div>
                        );
                      }
                    })}
                  </div>
                  <div style={{ paddingBottom: '15px' }}>
                    Total: {'  '}
                    <FormattedNumber
                      value={quote ? get(quote, 'gross') : 0}
                      style="currency"
                      currency={get(quote, 'currency')}
                    />
                  </div>
                </Col>
              )
            })}
          </Row>

        </div>
      </div>
    )
  }

  public renderLoader = () => {
    return (
      <div className="schemes-loader text-center">
        <Loading/>
        <p>
          Please wait whilst we generate your premiums...
        </p>
      </div>
    );
  }

  public goToTripDetails = () => {
    const { actions } = this.props;
    actions.push('/trip-details');
  }

  private hasScreeningRegionErrors = (errors) => {
    let match = [];
    if (errors && errors.length) {
      errors.forEach((e) => {
        match = e.match('The screening region is incorrect  - please re-screen');
      });
    }
    return match ? match.length : 0;
  }

  private unableToOfferQuote = () => {
    return (
      <h1>Unfortunately, based on the information provided we are unable to offer you a quote.</h1>
    )
  }

  public errorMessage = (errors, referrals, oneOrMoreQuote0Premium) => {
    const maxAgeExceeded = referrals.some((referral) => {
      return referral.name === 'max_age';
    });

    const { quote, startDate, endDate, options } = this.props;
    const start = parse(startDate, 'dd/MM/yyyy', new Date());
    const end = parse(endDate, 'dd/MM/yyyy', new Date());
    const singleTripDuration = differenceInDays(end, start);
    let hasWinterSportsExclusion = false;

    const hasWinterSportsExclusionScreening = some(quote.application.travellers, (traveller) => {
      return traveller.screening && traveller.screening.meta.hasWinterSportsExclusion && options.winter_sports;
    });

    if (options.winter_sports && singleTripDuration > 21) {
      hasWinterSportsExclusion = true;
    }

    if (hasWinterSportsExclusionScreening) {
      return (
        <Signposting medical={true} type={SignpostType.TYPE_A}/>
      );
    }

    if (referrals.length) {
      const getMedicalDeclines = filter(referrals, (rules) => {
        return get(rules, 'is_medical', false);
      });

      if (getMedicalDeclines.length > 0) {
        return (
          <Signposting medical={true} type={SignpostType.TYPE_A}/>
        );
      } else {

        if (maxAgeExceeded) {
          return (
            <>
              {this.showAgeErrorMessage()}
            </>
          )
        }
        return (
          <div>
            {this.unableToOfferQuote()}
          </div>
        );
      }
    }

    const containsMedicalErrors = errors
      && (
        errors.includes('Exceeds maximum medical score')
        || errors.includes('Medical Condition has annual exclusion')
      );
    if (containsMedicalErrors) {
      return (
        <Signposting medical={true} type={SignpostType.TYPE_A}/>
      );
    }

    if (hasWinterSportsExclusion) {
      return (
        <div>
          <h1>No Quotes Available</h1>
          <p>
            Sorry we are unable to provide you for a quote for Winter Sports Cover.
          </p>
          <p>
            <Button bsSize="lg" bsStyle="primary" onClick={this.goToTripDetails}>Go Back</Button>
          </p>

        </div>
      );
    }

    const screeningRegionError = this.hasScreeningRegionErrors(errors) > 0;

    if (screeningRegionError) {
      return (
        <div>
          {this.unableToOfferQuote()}
        </div>
      );
    }

    const hasAgeErrors = Array.isArray(errors) && errors.filter(
      (error) => ['max age', 'above age criteria']
        .some((message) => error.toLowerCase().indexOf(message) >= 0),
    ).length;

    const hasAgeReferrals = referrals.some((referral) => {
      return referral.name === 'max_age' && !referral.option;
    });

    const showAgeErrorMessage = hasAgeErrors || hasAgeReferrals;

    // If any of the single premiums are 0 show this error
    if (oneOrMoreQuote0Premium) {
      return (
        <div>
          {this.unableToOfferQuote()}
        </div>
      );
    }

    return (
      <div>
        {this.unableToOfferQuote()}
        {showAgeErrorMessage && this.showAgeErrorMessage()}
      </div>
    );
  }

  private showAgeErrorMessage = () => {
    return (
      <>
        <br/>
        <p>
          Unfortunately, we’re unable to give you a quote on this product as our policies have an age restriction.
        </p>
        <p>But there are alternatives available that can help you to find cover. The British Insurance Brokers'
          Association (BIBA) has a list of regulated brokers that provide specialist travel insurance and will
          be able to point you in the right direction. Visit
          their website <a target="_blank" href="https://www.biba.org.uk/find-insurance/">
            www.biba.org.uk/find-insurance
          </a> or call BIBA on 0370 950 1790. Lines are open 9am to 5pm, Monday to Friday.</p>
      </>
    )
  }

  public render() {
    const {
      handleSubmit,
      quote,
      submitting,
      valid,
      tripType,
      submitFailed,
      startDate,
      endDate,
      branding,
      product,
      schemeId,
      options,
      signposted,
      isUpsell,
    } = this.props;
    const quotes = quote.quotes;
    const hasFinishedCalculation = values(quotes).length === 3;

    if (!tripType) {
      return null;
    }

    const start = parse(startDate, 'dd/MM/yyyy', new Date());
    const end = parse(endDate, 'dd/MM/yyyy', new Date());
    const diff = differenceInDays(end, start);
    const singleTripDuration = diff + 1;
    const hasExceedMaxUpsellDuration = singleTripDuration > 31;

    let noAvailableQuotes = false;
    let hasAnnualExclusion = false;
    let hideAnnualTrip = false;
    let hideAnnualTripNegatives = false;

    let errors = '';
    let referrals = [];
    const isSingle = tripType === 'single';
    const isAnnual = tripType === 'annual';

    forEach(quote.application.travellers, (traveller) => {
      if (tripType === 'single' && traveller.screening && traveller.screening.meta.hasAnnualExclusion === true) {
        hasAnnualExclusion = true;
      }
    });

    let oneOrMoreQuote0Premium = false;

    const bcp = branding.channel.bcp || null;
    const isAnnualTripAllowed = BusinessContinuityPlan.isSchemeTypeAllowed('annual', bcp);

    forEach(quotes, (tripTypes) => {
      if (!tripTypes) {
        return;
      }

      const singleTripQuote = tripTypes['Single Trip'];
      const annualTripQuote = tripTypes['Annual Multi Trip'];

      const errorTypes = ['errors', 'declines', 'referrals'];
      noAvailableQuotes = errorTypes.some((errorType) => {
        return isSingle && this.checkHasProperty(singleTripQuote, errorType)
          || isAnnual && this.checkHasProperty(annualTripQuote, errorType);
      });

      hideAnnualTrip = errorTypes.some((errorType) => {
        return isSingle && annualTripQuote && (this.checkHasProperty(annualTripQuote, errorType)
          || annualTripQuote.gross < singleTripQuote.gross);
      });

      if ((isSingle && singleTripQuote && singleTripQuote.gross <= 0)
        || (isAnnual && annualTripQuote && annualTripQuote.gross <= 0)) {
        oneOrMoreQuote0Premium = true;
      }

      if (isSingle && annualTripQuote && annualTripQuote.gross <= singleTripQuote.gross) {
        hideAnnualTripNegatives = true;
      }

      if (isSingle && this.checkHasProperty(singleTripQuote, 'errors')) {
        errors = singleTripQuote.errors;
      }

      if (isAnnual && this.checkHasProperty(annualTripQuote, 'errors')) {
        errors = annualTripQuote.errors;
      }

      if (isSingle && this.checkHasProperty(singleTripQuote, 'referrals')) {
        referrals = singleTripQuote.referrals;
      }

      if (isAnnual && this.checkHasProperty(annualTripQuote, 'referrals')) {
        referrals = annualTripQuote.referrals;
      }

    });

    if (quote.isCalculating) {
      return this.renderLoader();
    }

    const showAnnualQuotes =
      isAnnualTripAllowed
      && tripType === 'single'
      && !hasExceedMaxUpsellDuration
      && !hasAnnualExclusion
      && !hideAnnualTrip
      && !hideAnnualTripNegatives;

    return (
      <Form horizontal={true} onSubmit={handleSubmit} autoComplete="off">
        {isRequired && submitFailed && !valid && this.state.isSchemePresent && (
          <div className="error-block-container">
            <h1>Whoops!</h1>
            <p>Missing something? Please check the areas marked in orange before you continue.</p>
          </div>
        )}
        {!this.state.isSchemePresent && (
          <div className="error-block-container">
            <h1>Whoops!</h1>
            <p>Missing something? Please select a tier before you continue.</p>
          </div>
        )
        }
        {!noAvailableQuotes && !oneOrMoreQuote0Premium ? (
          <div className="scheme-choice-section">
            <h1>Choose your level of {tripType === 'single' ? 'Single Trip ' : 'Annual Multi-Trip '} cover</h1>

            <div className="available-quotes">
              {hasFinishedCalculation && (
                <Quotes
                  quote={quote}
                  product={product}
                  tripType={tripType}
                  schemeId={schemeId}
                  options={options}
                  onSingleSchemeSelected={this.onSingleSchemeSelected}
                  toggleMoreInfo={this.toggleMoreInfo}
                  moreInfoState={this.state.moreInfo}
                  {...this.props}
                />
              )}
            </div>

            {showAnnualQuotes && (
              <div>
                <h1>Upgrade to Annual Multi-Trip</h1>
                <div className="available-quotes">
                  {hasFinishedCalculation && this.renderAnnualQuotes()}
                </div>
              </div>
            )}

            {get(signposted, 'medicalLoad', false) && !isUpsell && (
              <div style={{ textAlign: 'initial' }}>
                <Signposting type={SignpostType.TYPE_A}/>
              </div>
            )}

            <div className="btn-bar">
              <Button
                bsStyle="primary"
                id="continue"
                onClick={this.checkForUpSell}
                bsSize="lg"
                className="pull-right"
                disabled={submitting || (submitFailed && !valid)}
              >
                CONTINUE
              </Button>
            </div>

            {this.renderUpSellModal()}
          </div>
        ) : (
          <div className="steps-container unable-box">
            {this.errorMessage(errors, referrals, oneOrMoreQuote0Premium)}
          </div>
        )}
      </Form>
    );
  }
}

let selector;
let previousValues;

export default flow([
  reduxForm({
    form: 'quote',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    onChange: (
      values: IPolicyApplication,
      dispatch,
      props: ISchemeChoiceFormProps,
    ) => {
      if (!previousValues) {
        previousValues = values;
        return;
      }

      let recalculateScore = false;

      if (difference(values.destinations, previousValues.destinations).length > 0) {
        recalculateScore = true;
      }

      if (values.region !== previousValues.region) {
        recalculateScore = true;
      }

      if (recalculateScore) {
        props.actions.quote.recalculateScreening();
      }

      previousValues = values;
    },
  }),
  (component) => {
    selector = formValueSelector('quote');

    return component;
  },
  connect(
    (state: IStore) => {
      const quote = state.quote;
      const options = selector(state, 'options');
      const tripType = selector(state, 'tripType');
      const schemeId = selector(state, 'schemeId');
      const startDate = selector(state, 'startDate');
      const endDate = selector(state, 'endDate');
      const travellers = selector(state, 'travellers');
      const region = selector(state, 'region');
      const password = selector(state, 'password');
      const premiums = selector(state, 'premiums');
      const passwordConfirmation = selector(state, 'passwordConfirmation');
      const timeOfQuote = selector(state, 'timeOfQuote');
      const mods = selector(state, 'mods');
      const quoteType = selector(state, 'quoteType');
      const maxTripDuration = selector(state, 'maxTripDuration');
      const signposted = selector(state, 'signposted');
      const isUpsell = selector(state, 'isUpsell');

      return {
        quote,
        tripType,
        schemeId,
        startDate,
        endDate,
        travellers,
        region,
        password,
        premiums,
        passwordConfirmation,
        timeOfQuote,
        mods,
        quoteType,
        options,
        maxTripDuration,
        signposted,
        isUpsell,
        auth: state.auth,
        branding: state.branding,
        quoteForEdit: state.quote.quoteForEdit || false
      };
    },
    (dispatch) => ({
      actions: {
        dispatch,
        submit: bindActionCreators(submit, dispatch),
        quote: bindActionCreators({ ...quoteActions }, dispatch),
        push: bindActionCreators(push, dispatch),
        recalculateScore: bindActionCreators(recalculateScore, dispatch),
      },
    }),
  ),
])(SchemeChoiceForm);
