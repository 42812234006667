import axios from 'axios';
import { ICoverDetailsForm, IMtaActions, IMtaFormNames, IPersonalDetailsForm, PageForm } from './models';
import { IStore } from '../../redux/IStore';
import { change, getFormValues } from 'redux-form';
import { mtaForms } from '../../data/api/transformers';
import { push } from 'react-router-redux';
import { cloneDeep, each, filter, find, forEach, get } from 'lodash';
import {
  MTA_IS_LOADING,
  MTA_SET_CALCULATED_QUOTES,
  MTA_SET_CANCELLATION_OPTIONS,
  MTA_SET_COMPLETE,
  MTA_SET_DESTINATIONS,
  MTA_SET_PAYMENT_MODAL,
  MTA_SET_POLICY_PAYMENT_METHOD,
  MTA_SET_QUOTE,
  MTA_SET_SELECTED_COVER_DETAIL,
  MTA_SET_SIGNPOST,
  MTA_SET_STATE_CITIES,
  MTA_SET_UPSELL_OPTIONS,
  MTA_SET_UPSELL_RULES,
  MTA_UPDATE_FAILED,
  MTA_UPDATE_RESET,
  MTA_UPDATE_SUCCESS,
  MTA_VOUCHER_CODE_ERROR,
  MTA_VOUCHER_CODE_RESET,
  MTA_VOUCHER_CODE_SUCCESS,
  SET_POLICY_INFO,
  SET_SELECTED_POLICY,
} from './reducer';
import { Endpoints } from '../../data/api/endpoints';
import { url } from '../../data/api/util';
import { IPolicy, IPolicyApplication, IPremium } from '../../data/api/models';
import * as api from '../../data/api/api';
import { recalculateScore } from '../verisk/reducer';
import Heap from '../../helpers/Heap';
import {
  IDiscount
} from '../../scenes/CustomerAreaNew/scenes/MakeAChange/components/CancelPolicy/components/CancellationDiscountCard';
import { quoteHasMedical } from '../../helpers/signpost';

export function setSelectedPolicy(policy: IPolicy) {
  return (dispatch) => {
    dispatch({ type: SET_SELECTED_POLICY, payload: policy })
  };
}

export const resetAfterSubmit = () => {
  return (dispatch) => {
    dispatch({ type: MTA_UPDATE_RESET })
  };
}

export const setCompleteMTA = () => {
  return (dispatch) => {
    dispatch({ type: MTA_SET_COMPLETE, payload: true })
  };
}

export const setMTACancellationOptions = (data) => {
  return (dispatch) => {
    dispatch({ type: MTA_SET_CANCELLATION_OPTIONS, payload: data })
  };
}

export const setSignPost = (data) => {
  return (dispatch) => {
    dispatch({ type: MTA_SET_SIGNPOST, payload: data })
  };
}

export function setSelectedMta(selectedMta: string) {
  return (dispatch) => {
    dispatch({ type: MTA_SET_SELECTED_COVER_DETAIL, payload: selectedMta })
  };
}

export function setUpsellRules(canUpsell: boolean) {
  return (dispatch) => {
    dispatch({ type: MTA_SET_UPSELL_RULES, payload: canUpsell })
  }
}

export function setUpsellOptions(data: any) {
  return (dispatch) => {
    dispatch({ type: MTA_SET_UPSELL_OPTIONS, payload: data })
  }
}

const setMTADestinations = (destinations) => ({ type: MTA_SET_DESTINATIONS, payload: { destinations } });
const setMTAStateCities = (stateCities) => ({ type: MTA_SET_STATE_CITIES, payload: { stateCities } });
export const mtaIsLoading = (value) => {
  return { type: MTA_IS_LOADING, payload: value }
}

export const getSelectedScheme = () => {
  return (dispatch, getState) => {
    const state: IStore = getState();
    const formValues: ICoverDetailsForm | any = getFormValues('cover-details')(state);
    return find(state.mta.quotes, ['scheme.id', get(formValues, 'data.attributes.scheme_id')]);
  };
};

export const getMTAQuote = (schemeId) => {
  return (dispatch, getState) => {
    const state: IStore = getState();
    return find(state.mta.quotes, ['scheme.id', schemeId]);
  };
};

const getCustomerMtaOptions = () => {
  return (dispatch, getState) => {
    const state: IStore = getState();
    return get(state.branding.channel.products[0], 'metadata.direct_customer_mta_options', null);
  };
};

export function resetMtaData() {
  return (dispatch) => {
    dispatch({ type: MTA_SET_SELECTED_COVER_DETAIL, payload: null });
    dispatch({ type: MTA_SET_POLICY_PAYMENT_METHOD, payload: null });
    dispatch({ type: MTA_SET_CALCULATED_QUOTES, payload: null });
    dispatch({ type: MTA_SET_QUOTE, payload: null });
    dispatch({ type: MTA_SET_SIGNPOST, payload: { isSignposted: false, isMedical: false } });
    dispatch({ type: MTA_UPDATE_RESET });
    dispatch({ type: MTA_SET_COMPLETE, payload: false });
  };
}

export function submitPersonalDetailsForm() {
  return (dispatch, getState) => {
    const state: IStore = getState();
    const formData: IPersonalDetailsForm = getFormValues(IMtaFormNames.PERSONAL_DETAILS)(state);
    return axios.post(
      url(Endpoints.UPDATE_POLICY_PERSONAL_DETAILS, { params: { id: formData.policyId } }),
      mtaForms.personalDetailsToJsonApi(formData),
      { headers: { 'Content-Type': 'application/vnd.api+json' } },
    ).then((response) => {
      dispatch({
        type: MTA_UPDATE_SUCCESS,
        payload: { changes: response.data.meta.changes, policyId: formData.policyId },
      });
      dispatch(push(`/account/make-a-change/complete`));
    }).catch((err) => {
      dispatch({ type: MTA_UPDATE_FAILED, payload: err });
      dispatch(push(`/account/make-a-change/complete`));
    });
  };
}

export const handleMTASubmit = (redirectUrl: string = null) => {
  return (dispatch, getState) => {
    const state: IStore = getState();
    const formValues: ICoverDetailsForm | any = getFormValues('cover-details')(state);
    if (state.mta.complete) {
      dispatch(push('/account/make-a-change'));
    } else {
      switch (formValues.page) {
        case PageForm.MTA_DECLARATION:
          if (formValues && formValues.coverDetailsDeclaration) {
            dispatch(push(redirectUrl));
          }
          break;
        case PageForm.MTA_ADDITIONAL_COVER:
        case PageForm.MTA_CHANGE_REGION:
        case PageForm.MTA_CHANGE_DESTINATION:
        case PageForm.MTA_CHANGE_TRIP_TYPE:
        case PageForm.MTA_CHANGE_TRIP_DATES:
        case PageForm.MTA_REMOVE_TRAVELLER:
        case PageForm.MTA_CHANGE_TIER:
        case PageForm.MTA_CHANGE_TRIP_DURATION:
        case PageForm.MTA_REMOVE_OPTION:
          mtaSummaryPage(dispatch, formValues);
          break;
        case PageForm.MTA_ADDITIONAL_TRAVELLERS:
          dispatch(push('/account/make-a-change/mta/medical-declaration'));
          break;
        case PageForm.MTA_MEDICAL_DECLARATION:
          const promises = [];
          promises.push(dispatch(storeMtaPolicyApplication(applyChanges(formValues))));
          return Promise.all(promises);
        case PageForm.MTA_SUMMARY:
          dispatch({ type: MTA_UPDATE_FAILED, payload: null });
          purchaseMTA(dispatch, formValues);
          break;
      }
    }
  };
};

const mtaSummaryPage = (dispatch, formValues) => {
  dispatch(storeMtaPolicyApplication(applyChanges(formValues))).then(() => {
    dispatch(push('/account/make-a-change/mta/summary'));
  });
}

const applyChanges = (formValues) => {
  delete formValues.data.attributes.documents;
  delete formValues.data.attributes.premiums;
  formValues.data.attributes.metadata.purchase_channel = 'admiral-b2c';
  return formValues;
}

const purchaseMTA = (dispatch, formValues) => {
  dispatch(mtaIsLoading(true));
  dispatch(purchase(formValues))
    .then(() => {
      dispatch(mtaIsLoading(false));
      dispatch({
        type: MTA_UPDATE_SUCCESS,
        payload: { changes: null, policyId: formValues.data.attributes.id },
      });
      dispatch(push(`/account/make-a-change/complete`));
      Heap.trackEvent('PurchaseSuccess', formValues?.data?.attributes, 'policy')
    })
    .catch((error) => {
      dispatch(mtaIsLoading(false));
      let rethrow = true;
      let message = null;
      each(error.response.data.errors, (e: any) => {
        if (e.id !== 'redirect-to-provider') {
          message = e.detail;
          return;
        }
        dispatch({ type: MTA_SET_PAYMENT_MODAL, payload: { open: true, transactionId: e.data.transactionId } });
        rethrow = false;
      });

      if (!rethrow) {
        return;
      }
      if (message) {

        dispatch({ type: MTA_UPDATE_FAILED, payload: message });
        if (process.env.BROWSER) {
          window.scrollTo(0, 0);
        }
      }
      throw error;
    });
};

export const storeMtaPolicyApplication = (formValues) => {
  return (dispatch) => {
    dispatch(mtaIsLoading(true));
    return axios.post(
      url(Endpoints.CUSTOMER_APPLICATIONS_STORE),
      formValues,
      { headers: { 'Content-Type': 'application/vnd.api+json' } },
    ).then((response) => {
      dispatch({
        type: MTA_SET_QUOTE,
        payload: response.data,
      });
      dispatch(mtaIsLoading(false));
      return true;
    }).catch(() => {
      console.log('Mta store error');
      dispatch(mtaIsLoading(false));
    });
  };
};

export const purchase = (formValues, premiumToIssue = null) => {
  return (dispatch, getState) => {
    const state: IStore = getState();
    const mtaQuote = state.mta.mtaQuote;
    const premium = premiumToIssue ? premiumToIssue : get(getSelectedScheme(), 'premium', null);
    return api.issueMTAPolicyApplication(formValues, mtaQuote.data.id, premium)
      .then((data) => {
        return data;
      });
  };
};

export const calculateMtaPremiums = (values: any = null) => {
  return (dispatch, getState) => {
    const state: IStore = getState();
    const formValues: ICoverDetailsForm = values ? values : getFormValues('cover-details')(state);
    dispatch(mtaIsLoading(true));
    return axios.post(
      url(Endpoints.CUSTOMER_MTA_CALCULATE_PREMIUMS, { params: { id: formValues.data.attributes.id } }),
      formValues,
      { headers: { 'Content-Type': 'application/vnd.api+json' } },
    ).then((response) => {
      dispatch({
        type: MTA_SET_CALCULATED_QUOTES,
        payload: response.data.meta,
      });
      dispatch(mtaIsLoading(false));
      return true;
    }).catch(() => {
      dispatch(mtaIsLoading(false));
      console.log('Calculation error');
    });
  };
};

export const getPolicyInfo = () => {
  return (dispatch, getState) => {
    const state: IStore = getState();
    if (!state.mta.selectedPolicy) {
      return;
    }
    const selectedPolicyId = get(state, 'mta.selectedPolicy.id', null);
    dispatch(mtaIsLoading(true));
    return axios.get(
      url(Endpoints.CUSTOMER_POLICY_GET_INFO, { params: { id: selectedPolicyId } }),
      { headers: { 'Content-Type': 'application/vnd.api+json' } },
    ).then((response) => {
      dispatch({ type: SET_POLICY_INFO, payload: response.data })
      dispatch(mtaIsLoading(false));
    }).catch(() => {
      dispatch(mtaIsLoading(false));
    });
  }
}

export const getCustomerPolicyPaymentMethod = () => {
  return (dispatch, getState) => {
    const state: IStore = getState();
    if (!state.mta.selectedPolicy) {
      return;
    }
    const selectedPolicyId = get(state, 'mta.selectedPolicy.id', null);
    dispatch(mtaIsLoading(true));
    return axios.get(
      url(Endpoints.GET_CUSTOMER_POLICY_PAYMENT_METHOD, { params: { id: selectedPolicyId } }),
      { headers: { 'Content-Type': 'application/vnd.api+json' } },
    ).then((response) => {
      dispatch({
        type: MTA_SET_POLICY_PAYMENT_METHOD,
        payload: response.data.meta,
      });
      dispatch(mtaIsLoading(false));
      return true;
    }).catch(() => {
      dispatch(mtaIsLoading(false));
    });
  }
}

const recalculateScreening = (selection, tripType) => (dispatch, getState) => {
  const MTAApplication: ICoverDetailsForm | any = getFormValues('cover-details')(getState());
  const adults = get(MTAApplication, 'data.attributes.metadata.adults', []);
  const children = get(MTAApplication, 'data.attributes.metadata.children', []);
  dispatch(recalculateTravellerScore(adults, selection, 'adults', tripType));
  dispatch(recalculateTravellerScore(children, selection, 'children', tripType));
};

const recalculateTravellerScore = (travellers, selectedRegion, type, tripType) => (dispatch) => {
  // Add a return type here if the screening session is healix 🚑
  travellers.forEach((traveller, key) => {
    if (!traveller.screening_id) {
      return;
    }

    dispatch(mtaIsLoading(true));
    dispatch(recalculateScore(
      traveller.screening_id,
      tripType === 'annual' ? selectedRegion : null,
      tripType === 'single' ? selectedRegion : [],
      (screening, screeningId) => {

        if (type === 'adults') {
          dispatch(change('cover-details', `data.attributes.metadata.adults[${key}].screeningSessionId`, screeningId));
          dispatch(change('cover-details', `data.attributes.metadata.adults[${key}].screening`, screening));
        }
        if (type === 'children') {
          dispatch(change('cover-details', `data.attributes.metadata.children[${key}].screeningSessionId`, screeningId));
          dispatch(change('cover-details', `data.attributes.metadata.children[${key}].screening`, screening));
        }
      },
      true
    ));
  });
}

const loadDestinations = () => (dispatch) => {
  return api.listDestinations()
    .then((destinations) => {
      dispatch(setMTADestinations(destinations));
      return destinations;
    });
};

const loadStateCities = () => (dispatch) => {
  return api.listStateCities()
    .then((stateCities) => {
      dispatch(setMTAStateCities(stateCities));
      return stateCities;
    });
};

const addEndorsements = () => (dispatch, getState) => {
  const mta = getState().mta;
  const MTAApplication: ICoverDetailsForm | any = getFormValues('cover-details')(getState());
  const selectedScheme = find(mta.quotes, ['scheme.id', get(MTAApplication, 'data.attributes.scheme_id')]);
  const endorsements = get(selectedScheme, 'mta.endorsements', null);
  const endorsementsForMeta = [];
  if (endorsements && endorsements.length) {

    let content = '';
    let code = '';
    forEach(endorsements, (endorsement) => {
      content += endorsement.content + '\n\n';
      if (get(endorsement, 'metadata.code')) {
        code += endorsement.metadata.code + '; ';
      }
    });

    endorsementsForMeta.push({
      title: 'Endorsement Policy Letter',
      content,
      type: 'generated',
      metadata: { code, source: 'B2C' },
    });

    dispatch(change('cover-details', 'data.attributes.metadata.endorsements', endorsementsForMeta));
  } else {
    dispatch(change('cover-details', 'data.attributes.metadata.endorsements', []));
  }
}


export const getDetailsForCancellation = (policyId) => {
  return (dispatch, getState) => {
    const state: IStore = getState();
    if (!state.mta.selectedPolicy) {
      return;
    }
    dispatch(mtaIsLoading(true));
    return axios.get(url(Endpoints.GET_POLICY_CANCELLATION_DETAILS, { params: { id: policyId } }),
      { headers: { 'Content-Type': 'application/vnd.api+json' } }).then(response => {
      dispatch(mtaIsLoading(false));
      return response.data;
    }).catch(() => {
      dispatch(mtaIsLoading(false));
      console.log('Policy cannot be canceled at this time');
    });
  };
}

export const cancelPolicy = (values, policyId) => {
  return (dispatch) => {
    dispatch(mtaIsLoading(true));

    const valuesCloned = cloneDeep(values);
    const cancellationReason = valuesCloned.meta?.reason?.cancellation_reason;
    const reasonMappings = {
      'not_travelling_soon': 'Not travelling soon',
      'poor_service': 'Poor Service',
      'too_expensive': 'It\'s too expensive',
      'cover_not_suitable': 'Cover or limits aren\'t suitable',
      'other': 'Other'
    }

    if (cancellationReason) {
      valuesCloned.meta.reason.cancellation_reason = get(reasonMappings, cancellationReason, '');
    }
    valuesCloned.meta.reason = JSON.stringify(valuesCloned.meta.reason);

    return axios.post(url(Endpoints.MTA_CANCEL_POLICY, { params: { id: policyId } }),
      valuesCloned,
      { headers: { 'Content-Type': 'application/vnd.api+json' } }).then(() => {
      dispatch(mtaIsLoading(false));
      dispatch({
        type: MTA_UPDATE_SUCCESS,
        payload: { changes: null, policyId },
      });
      dispatch(push('/account/make-a-change/cancellation-complete'));
    }).catch(error => {
      dispatch(mtaIsLoading(false));
      dispatch({
        type: MTA_UPDATE_FAILED,
        payload: { changes: null, payload: error },
      });
      dispatch(push('/account/make-a-change/cancellation-complete'));
    });
  }
}

export const cancelRenewal = (formValues, renewalId) => {
  return (dispatch) => {
    dispatch(mtaIsLoading(true));

    const valuesCloned = cloneDeep(formValues);
    const cancellationReason = valuesCloned.meta?.reason?.cancellation_reason || '';
    const cancellationNotes = valuesCloned.meta?.reason?.cancellation_notes || '';
    const reasonMappings = {
      'not_travelling_soon': 'Not travelling soon',
      'poor_service': 'Poor Service',
      'too_expensive': 'It\'s too expensive',
      'cover_not_suitable': 'Cover or limits aren\'t suitable',
      'other': 'Other'
    }

    if (cancellationReason) {
      const mapping = get(reasonMappings, cancellationReason, '');
      valuesCloned.meta.reason.cancellation_reason = mapping === 'Other' ? mapping + ' : ' + cancellationNotes : mapping;
    }
    valuesCloned.meta.reason = JSON.stringify(valuesCloned.meta.reason);

    return axios.post(url(Endpoints.LAPSE_RENEWAL, { params: { id: renewalId } }),
      valuesCloned,
      { headers: { 'Content-Type': 'application/vnd.api+json' } }).then(() => {
      dispatch(mtaIsLoading(false));
      dispatch({
        type: MTA_UPDATE_SUCCESS,
        payload: { changes: null, renewalId },
      });
      dispatch(push('/account/make-a-change/cancellation-complete'));
    }).catch(error => {
      dispatch(mtaIsLoading(false));
      dispatch({
        type: MTA_UPDATE_FAILED,
        payload: { changes: null, payload: error },
      });
      dispatch(push('/account/make-a-change/cancellation-complete'));
    });
  }
}

export const calculateCancellationDiscount = (discount: IDiscount) => {
  return (dispatch, getState) => {
    const state: IStore = getState();
    const selectedPolicy = cloneDeep(state.mta.selectedPolicy);
    if (!selectedPolicy) {
      return;
    }

    const schemeId = get(selectedPolicy, 'metadata.scheme_id');
    delete selectedPolicy.documents;
    delete selectedPolicy.premiums;
    selectedPolicy.metadata.purchase_channel = 'admiral-b2c';
    selectedPolicy.metadata.campaign = discount.id;
    selectedPolicy.metadata.campaign_code = discount.code

    selectedPolicy.effective_date = new Date();
    selectedPolicy.metadata.portal_mta = true;
    selectedPolicy.mta = true;
    selectedPolicy.scheme_id = schemeId;

    const data = {
      data: {
        attributes: selectedPolicy
      }
    }

    return Promise.resolve(dispatch(calculateMtaPremiums(data))).then(() => {
      const selectedScheme = dispatch(getMTAQuote(schemeId));
      return { policyData: data, selectedScheme };
    })

  }
}

const findChannelKeyFromName = (quote, name: string) => {
  if (quote.channels && quote.channels.length) {
    const channel = quote.channels.filter((channel) => {
      return channel.name === name;
    }).shift();

    if (channel) {
      return channel.key;
    }
  }

  return null;
}

const calculateRenewalDiscount = (discount: IDiscount) => {
  return (dispatch, getState) => {
    const state: IStore = getState();
    const { quote, mta } = state;
    const selectedQuote = quote.application;
    const productId = get(mta, 'policyInfo.meta.renewalProductId')
    let channel = null;

    if (selectedQuote.channelName && quote.channels && quote.channels.length) {
      channel = findChannelKeyFromName(quote, selectedQuote.channelName);
    }

    if (!channel) {
      channel = 'admiral-b2c'
    }

    const pos = channel.search('-renewal');
    if (pos > -1) {
      channel = channel.substr(0, pos);
    }
    channel += '-renewal';

    selectedQuote.channel = { key: channel };
    selectedQuote.campaignCode = discount.code;
    selectedQuote.campaign = discount.id;

    return api.calculateQuote(selectedQuote, productId, null)
      .then((premiums: IPremium[]) => {
        const selectedPremium: IPremium = find(premiums, ['scheme.id', selectedQuote.schemeId]);
        const hasMedicalAdded = quoteHasMedical(null, get(selectedQuote, 'travellers'));

        if (selectedPremium) {
          let hasMedicalDecline = false;
          let hasReferralRule = false;
          const isSignPosted = get(selectedPremium, 'isSignPosted', false);
          const referrals = get(selectedPremium, 'referrals', []);
          if (filter(referrals, ['is_medical', true]).length > 0) {
            hasMedicalDecline = true;
          }

          if (referrals.length && !hasMedicalDecline) {
            hasReferralRule = true;
          }
          const signPosted = isSignPosted && hasMedicalAdded || hasMedicalDecline
          return {
            selectedScheme: selectedPremium,
            selectedQuote,
            isSignposted: signPosted,
            isMedical: hasMedicalDecline,
            hasReferralRule
          }
        }
      });
  }
}

const issueDiscountMTA = (formValues, premium, discount, reason) => {
  return (dispatch, getState) => {
    const state: IStore = getState();
    const mtaQuote = state.mta.mtaQuote;
    const policyId = state.mta?.selectedPolicy?.id;
    dispatch(mtaIsLoading(true));
    dispatch(purchase(formValues, premium))
      .then(() => {
        dispatch({
          type: MTA_UPDATE_SUCCESS,
          payload: {
            changes: 1,
            policyId,
            returnPremium: Math.abs(premium.gross).toFixed(2),
            discount,
            reason
          },
        });
        dispatch(mtaIsLoading(false));
      })
      .catch((error) => {
        dispatch(mtaIsLoading(false));
        dispatch({ type: MTA_UPDATE_FAILED, payload: error });
        if (process.env.BROWSER) {
          window.scrollTo(0, 0);
        }
        throw error;
      });
  }
};

const applyRenewalDiscount = (formValues, premium, discount, reason) => {
  return (dispatch, getState) => {
    const state: IStore = getState();
    const { mta, quote } = state;
    const policyId = mta?.selectedPolicy?.id;
    const channels = get(quote, 'channels', [])
    const channel = find(channels, ['key', formValues.channel?.key])
    const channelId = channel.id;

    dispatch(mtaIsLoading(true));

    const promise = api.saveQuote(formValues, channelId, formValues.brokerId, { calculate: true });

    return promise
      .then((application: IPolicyApplication) => {
        dispatch({
          type: MTA_UPDATE_SUCCESS,
          payload: {
            changes: 1,
            policyId,
            renewalPremium: Math.abs(premium.gross).toFixed(2),
            quoteReference: formValues.quoteReference,
            discount,
            reason
          },
        });
        dispatch(mtaIsLoading(false));
        dispatch(push('/account/make-a-change/renewal-discount-applied-complete'));
      }).catch((error) => {
        dispatch(mtaIsLoading(false));
        dispatch({ type: MTA_UPDATE_FAILED, payload: error });
        dispatch(push('/account/make-a-change/renewal-discount-applied-complete'));
        throw error;
      });
  }
};

const checkVoucherCode = (campaignCode) => (dispatch, getState) => {
  const state: IStore = getState();
  const { branding } = state;
  return api.checkCampaignCode(campaignCode, branding.channel.id)
    .then((response) => {
      if (response.data) {
        const preventDisplay = !!(response.data.attributes.metadata &&
          response.data.attributes.metadata.prevent_display);
        const voucherEnded = response.data.attributes && response.data.attributes.status === 'ended';
        const regexVoucherCode = !!(response.data.attributes && response.data.attributes.regex_voucher_code);

        if (voucherEnded) {
          throw Error('Voucher code ended');
        }

        dispatch(voucherCodeSuccess({ preventDisplay, regexVoucherCode }));
        dispatch(setCampaign(response.data));
        return response.data;
      }

    })
    .catch((error) => {
      dispatch({ type: MTA_VOUCHER_CODE_ERROR });
      setTimeout((() => {
        dispatch(resetVoucherCode());
      }), 3000);
    });
};

const setCampaign = (campaign) => (dispatch, getState) => {
  dispatch(change('cover-details', 'data.attributes.metadata.campaign', campaign.id));
  dispatch(change('cover-details', 'data.attributes.metadata.campaign_code', campaign.attributes.code));
};

const voucherCodeSuccess = (payload) => {
  return {
    type: MTA_VOUCHER_CODE_SUCCESS,
    payload: {
      preventDisplay: payload.preventDisplay,
      regexVoucherCode: payload.regexVoucherCode,
    },
  };
};

export const resetVoucherCode = () => (dispatch) => {
  dispatch({ type: MTA_VOUCHER_CODE_RESET });
}

export const mtaActions: IMtaActions = {
  setSelectedPolicy,
  storeMtaPolicyApplication,
  setSelectedMta,
  submitPersonalDetailsForm,
  handleMTASubmit,
  calculateMtaPremiums,
  resetMtaData,
  getSelectedScheme,
  resetAfterSubmit,
  setCompleteMTA,
  getCustomerMtaOptions,
  setSignPost,
  getCustomerPolicyPaymentMethod,
  getPolicyInfo,
  recalculateScreening,
  addEndorsements,
  loadDestinations,
  loadStateCities,
  getDetailsForCancellation,
  cancelPolicy,
  cancelRenewal,
  setUpsellRules,
  setMTACancellationOptions,
  getMTAQuote,
  calculateCancellationDiscount,
  calculateRenewalDiscount,
  issueDiscountMTA,
  applyRenewalDiscount,
  setUpsellOptions,
  checkVoucherCode,
  resetVoucherCode,
};
