import * as React from 'react';
import Select from 'react-select';
import { findCondition, startDeclaration } from '../../../../../../services/verisk/reducer';
import { debounce } from 'lodash';
import { Button } from 'react-bootstrap';
import { AlertConditionSearch } from './Components';

export default class ConditionSearch extends React.Component<any, any> {
  public state = {
    condition: '',
  };

  constructor(props) {
    super(props);

    this.search = debounce(this.search, 300);
  }

  private search = (query) => {
    this.props.dispatch(findCondition(query));
  }

  public componentDidUpdate(prevProps, prevState) {
    if (prevProps.conditionProps !== prevState.condition) {
      this.props.conditionChange(prevState.condition);
    }
  }

  private setCondition = (condition) => {
    this.setState({ condition },
      this.props.conditionChange(condition),
    );
  }

  private handleSubmit = () => {
    const { dispatch } = this.props;
    const { condition } = this.state;

    dispatch(startDeclaration(condition));
  }

  public render() {
    const { index, screening } = this.props;
    const { condition } = this.state;

    return (
      <div className="search-conditions form-horizontal">
        <div className="form-group">
          {screening?.hasSurgicalProcedure && (<AlertConditionSearch
            text={'You have searched for a procedure or a surgery, please enter the name for the medical condition instead.'}/>)}
          {screening?.hasMisspelledConditions && (<AlertConditionSearch
            text={'No medical condition was found matching your search. However, did you mean:'}/>)}
          <label className="control-label col-xs-3 full-width-xs">Search conditions</label>
          <div className={(condition ? 'col-xs-6' : 'col-xs-9') + ' full-width-xs'}>
            <Select
              name={'conditions-search-' + index}
              cache="false"
              value={condition}
              isLoading={screening.isSearching}
              options={screening?.hasSurgicalProcedure ? [] : screening.conditions}
              placeholder="Type Condition"
              filterOptions={false}
              onInputChange={this.search}
              onChange={this.setCondition}
            />
          </div>
          {condition && (
            <div>
              <div className="col-xs-3 full-width-xs">
                {/*<br className="visible-xs visible-sm"/>*/}
                <Button
                  className="screen-button"
                  bsStyle="primary"
                  disabled={screening.isSubmitting}
                  onClick={this.handleSubmit}
                  block={false}
                >Screen
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
