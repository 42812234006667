import { ICustomer, IPolicyApplication, IPremium, IQuotes, ITraveller } from '../models';
import { format, isValid, parse } from 'date-fns';
import * as uuid from 'uuid/v4';
import * as document from './documents';
import * as paymentMethod from './activePaymentMethods';
import { getIncludedResource } from '../util';
import { forEach, get } from 'lodash';
import { fromJsonApi as fromChannelJsonApi } from './channels';
import { isoFormat, stripTimezone } from '../../../helpers/DateManipulation';

export const dateFromApi = (date) => {
  if (!date) {
    return '';
  }

  const parsedDate = new Date(date);

  return isValid(parsedDate) ? format(parsedDate, 'dd/MM/yyyy') : '';
};

export const dateToApi = (date) => {
  if (!date) {
    return '';
  }

  const parsedDate = stripTimezone(parse(date, 'dd/MM/yyyy', new Date()));

  return isValid(parsedDate) ? format(parsedDate, isoFormat) : '';
};

const getSalesChannel = (policyApplication: IPolicyApplication): string => {
  const channelName = get(policyApplication, 'channelName', '');
  if (channelName === 'Call Centre' || channelName === 'Call Centre Renewal') {
    return 'call-centre';
  } else if (policyApplication.channel) {
    return policyApplication.channel.key;
  } else {
    return 'admiral-b2c';
  }
};

export const mapTraveller = (type, traveller: any, key: number): ITraveller => {
  const data: ITraveller = {
    id: traveller.id ? traveller.id : uuid(),
    title: traveller.title,
    firstName: traveller.first_name,
    lastName: traveller.last_name,
    dob: dateFromApi(traveller.dob),
    type,
    screening: traveller.screening,
    screeningSessionId: traveller.screening_id,
    marketingOptions: traveller.marketing_options,
    metadata: get(traveller, 'metadata', null)
  };

  if (key === 0 && type === 'adult') {
    data.email = traveller.email;
    data.phone = traveller.phone1;
    data.address = traveller.address;
  }

  return data;
};

export function fromJsonApi(data, included = []): IPolicyApplication {
  const { id, attributes: { metadata } } = data;

  const travellers = [];

  const premiums: IPremium[] = getIncludedResource(data, included, 'premiums', true).map((premium): IPremium => {
    const scheme = getIncludedResource(premium, included, 'scheme');
    const schemeBenefits = scheme.attributes.config
      ? scheme.attributes.config.benefits
      : premium.attributes.metadata.benefits;

    return {
      id: premium.id,
      schemeType: premium.attributes.metadata.scheme.raw_scheme_type,
      calculatedAt: premium.attributes.metadata.calculated_at,
      validUntil: premium.attributes.metadata.valid_until,
      currency: premium.attributes.currency,
      campaigns: premium.attributes.campaigns,
      tax: premium.attributes.tax,
      exTax: premium.attributes.exTax, // I think this should be ex_tax
      principal: premium.attributes.principal,
      grossExAdditional: premium.attributes.gross_ex_additional,
      gross: premium.attributes.gross,
      discount: premium.attributes.discount,
      taxRate: premium.attributes.tax_rate,
      isSignposted: premium.attributes.metadata.premium.is_signposted,
      benefits: premium.attributes.metadata.benefits.map((benefit) => ({
        name: benefit.name,
        text: benefit.text,
        description: benefit.description,
        inverse: benefit.inverse,
        excess: benefit.excess,
        displayName: benefit.display_name,
        shortName: benefit.short_name,
        deprecation: benefit.deprecation || null,
        subBenefits: benefit.sub_benefits && benefit.sub_benefits.map((subBenefit) => ({
          excess: subBenefit.excess,
          name: subBenefit.name,
          text: subBenefit.text,
          description: subBenefit.description,
        })),
      })),
      information: premium.attributes.metadata.information && {
        allBenefits: premium.attributes.metadata.information.all_benefits,
        standardExcess: premium.attributes.metadata.information.standard_excess,
        hasGroups: premium.attributes.metadata.information.has_groups || false,
        boltOns: premium.attributes.metadata.information.bolt_ons,
        upgrade: premium.attributes.metadata.information.upgrade && {
          schemeId: premium.attributes.metadata.information.upgrade.scheme,
          amount: premium.attributes.metadata.information.upgrade.amount,
          coverLevel: premium.attributes.metadata.information.upgrade.coverLevel,
        },
        maxTripDuration: premium.attributes.metadata.information.max_trip_duration,
        medicalCoverForCovid: premium.attributes.metadata.information.medical_cover_for_covid || false,
        preSignpostScheme: premium.attributes.metadata.information.is_pre_signpost_scheme || false,
        claimCalculated: premium.attributes.metadata.information.claim_calculated || null,
        startDate: premium.attributes.metadata.information.start_date,
        region: premium.attributes.metadata.information.region,
        tripType: premium.attributes.metadata.information.scheme_type,
        fraudCheck: premium.attributes.metadata.information.fraud_check || false,
        showRewardsBanner: premium.attributes.metadata.information.show_rewards_banner || false,
        upSellIncreaseRatio: premium.attributes.metadata.information.up_sell_increase_ratio || undefined,
        cprResponse: premium.attributes.metadata.information.cpr_response || undefined,
        timeOfQuote: premium.attributes.metadata.information.time_of_quote || null,
        mods: premium.attributes.metadata.information.mods || null,
        upsellMaxTripDuration: premium.attributes.metadata.information.upsell_max_trip_duration || null,
        controlGroup: premium.attributes.metadata.information.control_group || null,
        hideUkRegion: premium.attributes.metadata.information?.hide_uk_region || false,
      },
      declines: premium.attributes.metadata.declines,
      referrals: premium.attributes.metadata.referrals,
      errors: premium.attributes.metadata.errors && premium.attributes.metadata.errors,
      scheme: {
        id: scheme.id,
        coverLevel: scheme.attributes.cover_level,
        status: scheme.attributes.status,
        logo: scheme.attributes.logo,
        name: scheme.attributes.name,
        insurer: scheme.attributes.insurer,
        documents: premium.attributes.metadata.scheme.documents,
        includedOptions: scheme.attributes.metadata.included_options || [],
        allBenefits: schemeBenefits.map((benefit) => ({
          name: benefit.name,
          text: benefit.text,
          description: benefit.description,
          inverse: benefit.inverse,
          excess: benefit.excess,
          displayName: benefit.display_name,
          deprecation: benefit.deprecation || null,
          shortName: benefit.short_name,
          subBenefits: benefit.sub_benefits && benefit.sub_benefits.map((subBenefit) => ({
            excess: subBenefit.excess,
            name: subBenefit.name,
            text: subBenefit.text,
          })),
        })),
      },
      premiumBeforeAdjustment: premium.attributes.premium_before_adjustment && {
        currency: premium.attributes.premium_before_adjustment.currency,
        principal: premium.attributes.premium_before_adjustment.principal,
        medical: premium.attributes.premium_before_adjustment.medical,
        tax: premium.attributes.premium_before_adjustment.tax,
        gross: premium.attributes.premium_before_adjustment.gross,
        exTax: premium.attributes.premium_before_adjustment.ex_tax,
      },
    };
  });

  const customer: ICustomer = getIncludedResource(data, included, 'customer', true).map((customer): ICustomer => {

    return {
      expiredPolicies: [],
      id: customer.id,
      title: customer.attributes.title,
      firstName: customer.attributes.first_name,
      lastName: customer.attributes.last_name,
      companyName: customer.attributes.company_name,
      customerName: customer.attributes.customer_name,
      dob: dateFromApi(customer.attributes.dob),
      address: customer.attributes.address,
      email: customer.attributes.email,
      phone1: customer.attributes.phone1,
      phone2: customer.attributes.phone2,
      status: customer.attributes.status,
      deceased: customer.attributes.deceased,
      metadata: customer.attributes.metadata,
      policies: customer.policies,
      activePolicies: [],
      policyApplications: customer.policy_applications,
      renewals: customer.renewals,
      marketingOptions: customer.marketing_options,
    };
  });

  const quotes: IQuotes = {};

  premiums.forEach((premium) => {
    if (!quotes[premium.scheme.coverLevel]) {
      quotes[premium.scheme.coverLevel] = {};
    }

    quotes[premium.scheme.coverLevel][premium.schemeType] = premium;
  });

  if (metadata.adults) {
    metadata.adults.forEach((adult, key) => {
      travellers.push(mapTraveller('adult', adult, key));
    });
  }

  if (metadata.children) {
    metadata.children.forEach((child, key) => {
      travellers.push(mapTraveller('child', child, key));
    });
  }

  const quotePremiums = getIncludedResource(data, included, 'policies/premiums', true)
    .map((item) => quotePremiums.fromJsonApi(item, included));

  let documents = getIncludedResource(data, included, 'documents', true)
    .map((item) => document.fromJsonApi(item, included));

  if (!documents.length) {
    documents = getIncludedResource(data, included, 'channel_scheme_documents', true)
      .map((item) => document.fromJsonApi(item, included));
  }

  const activePaymentMethod = paymentMethod.fromJsonApi(data);
  const assignedPaymentMethods = paymentMethod.paymentMethodsOnPolicy(data)

  const channel = ((channel) => {
    if (!channel) {
      return;
    }

    return fromChannelJsonApi(channel, included);
  })(getIncludedResource(data, included, 'channel'));

  let endDate = '';
  if (metadata.end_date) {
    endDate = dateFromApi(metadata.end_date);
  }
  if (data.attributes.ends_at) {
    endDate = dateFromApi(format(new Date(data.attributes.ends_at), isoFormat));
  }

  // noinspection UnnecessaryLocalVariableJS
  const policyApplication: IPolicyApplication = {
    createAccount: false,
    industries: [],
    occupations: [],
    password: '',
    passwordConfirmation: '',
    payOnAccount: false,
    policyDeclaration: false,
    upSellIncreaseRatio: {},
    quotationTypes: metadata.quotation_types,
    quoteType: data.attributes.quote_type,
    renewingPolicyNumber: data.attributes.renewing_policy_number,
    renewingPolicyId: data.attributes.renewing_policy_id,
    renewalStatus: get(data, 'attributes.renewal_status', get(metadata, 'renewal_status', 'manual')),
    renewal: metadata?.renewal || false,
    residency: metadata.residency,
    quotationDeclaration: metadata.quotation_declaration,
    tripType: metadata.scheme_type,
    destinations: metadata.destinations,
    stateCities: metadata.state_cities,
    startDate: dateFromApi(metadata.start_date),
    endDate,
    maxTripDuration: metadata.max_trip_duration,
    groupType: metadata.traveller_group,
    travellers,
    campaignCode: metadata.campaign_code,
    occupation: metadata.occupation,
    industry: metadata.industry,
    claims: metadata.claims,
    criminal: metadata.criminal,
    region: metadata.region,
    options: metadata.options,
    quotes: metadata.quotes,
    medicalDeclaration: metadata.medical_declaration_questions,
    steps: metadata.steps || [],
    customer,
    schemeId: metadata.scheme_id,
    brokerId: metadata.broker_id,
    quoteReference: data.attributes.quote_reference,
    status: data.attributes.status ? data.attributes.status : null,
    insured: data.attributes.insured,
    quotePremiums,
    premiums,
    generateDocuments: false,
    emailSend: false,
    documents,
    channel,
    calculateAllSchemes: false,
    preventPatch: metadata.preventPatch,
    documentPack: metadata.document_pack,
    communications: {
      post: metadata.communications ? (metadata.communications.post || false) : false,
    },
    salesChannel: metadata.sales_channel ? metadata.sales_channel : null,
    channelName: data.attributes.channel_name ? data.attributes.channel_name : null,
    createdAt: data.attributes.created_at ? data.attributes.created_at : null,
    updatedAt: data.attributes.updated_at ? data.attributes.updated_at : null,
    activePaymentMethod,
    assignedPaymentMethods,
    version: data.attributes.version ? data.attributes.version : null,
    waitingListExclusion: metadata.waiting_list_exclusion || false,
    waitingListExclusionNote: metadata.waiting_list_exclusion_note || '',
  };

  if (metadata.additional_renewal_letter) {
    policyApplication.additionalRenewalLetter = metadata.additional_renewal_letter;
  }

  if (metadata.upsell_date) {
    policyApplication.upsellDate = metadata.upsell_date;
  }

  if (metadata.purchased_from_b2c) {
    policyApplication.purchasedFromB2C = metadata.purchased_from_b2c;
  }

  if (metadata.portal) {
    policyApplication.portal = metadata.portal;
  }

  if (metadata.purchase_channel) {
    policyApplication.purchaseChannel = metadata.purchase_channel;
  }

  if (metadata.campaign) {
    policyApplication.campaign = metadata.campaign;
  }

  if (id) {
    policyApplication.id = id;
  }

  if (quotes) {
    policyApplication.quotes = quotes;
  }

  if (metadata.capping_factor) {
    policyApplication.cappingFactor = metadata.capping_factor;
  }

  if (metadata.price_increase) {
    policyApplication.priceIncrease = metadata.price_increase;
  }

  if (metadata.capping_factor_breakdown_key) {
    policyApplication.cappingFactorBreakdownKey = metadata.capping_factor_breakdown_key;
  }

  if (metadata.fraud_check) {
    policyApplication.fraudCheck = metadata.fraud_check;
  }

  if (metadata.up_sell_increase_ratio) {
    policyApplication.upSellIncreaseRatio = metadata.up_sell_increase_ratio;
  }

  if (metadata.cpr_response) {
    policyApplication.cprResponse = metadata.cpr_response;
  }

  if (metadata.claim_calculated) {
    policyApplication.claimCalculated = metadata.claim_calculated;
  }

  if (metadata.signposted) {
    policyApplication.signposted = {
      medicalDecline: get(metadata, 'signposted.medical_decline', false),
      medicalLoad: get(metadata, 'signposted.medical_load', false),
    };
  }

  if (metadata.created_before_fixed_scheme_version_rule) {
    policyApplication.createdBeforeFixedSchemeVersionRule = metadata.created_before_fixed_scheme_version_rule;
  }

  if (metadata.time_of_quote) {
    policyApplication.timeOfQuote = metadata.time_of_quote;
  }

  if (metadata.mods) {
    policyApplication.mods = metadata.mods;
  }

  if (metadata.new_business_lag_days) {
    policyApplication.newBusinessLagDays = metadata.new_business_lag_days;
  }

  if (metadata.renewal_term) {
    policyApplication.renewalTerm = metadata.renewal_term;
  }

  if (metadata.previous_quote_date) {
    policyApplication.previousQuoteDate = metadata.previous_quote_date;
  }

  if (metadata.previous_start_date) {
    policyApplication.previousStartDate = metadata.previous_start_date;
  }

  return policyApplication;
}

export function toJsonApi(policyApplication: IPolicyApplication, channelId?: string, brokerId?: string, productId?: string) {
  const mapTraveller = (type) => (traveller: ITraveller, key: number) => {
    const data: any = {
      id: traveller.id,
      title: traveller.title,
      first_name: traveller.firstName,
      last_name: traveller.lastName,
      dob: dateToApi(traveller.dob),
      screening: traveller.screening,
      screening_id: traveller.screeningSessionId,
      conditions_covered: true,
      marketing_options: traveller.marketingOptions,
      metadata: traveller.metadata,
    };

    if (key === 0 && type === 'adult') {
      data.email = traveller.email;
      data.phone1 = traveller.phone;
      data.address = traveller.address;
    }

    return data;
  };

  const metadata: any = {
    scheme_type: policyApplication.tripType,
    quotation_types: policyApplication.quotationTypes,
    sales_channel: getSalesChannel(policyApplication),
    residency: policyApplication.residency,
    traveller_group: policyApplication.groupType,
    destinations: policyApplication.destinations,
    state_cities: policyApplication.stateCities,
    occupation: policyApplication.occupation,
    industry: policyApplication.industry,
    claims: policyApplication.claims,
    criminal: policyApplication.criminal,
    campaign_code: policyApplication.campaignCode,
    region: policyApplication.region ? policyApplication.region : '',
    start_date: dateToApi(policyApplication.startDate),
    end_date: dateToApi(policyApplication.endDate),
    max_trip_duration: policyApplication.maxTripDuration,
    adults: policyApplication.travellers.filter(({ type }) => type === 'adult').map(mapTraveller('adult')),
    children: policyApplication.travellers.filter(({ type }) => type === 'child').map(mapTraveller('child')),
    options: policyApplication.options,
    medical_declaration_questions: policyApplication.medicalDeclaration,
    quotation_declaration: policyApplication.quotationDeclaration,
    scheme_id: policyApplication.schemeId,
    policy_declaration: policyApplication.policyDeclaration,
    generate_documents: policyApplication.generateDocuments,
    email_send: policyApplication.emailSend,
    steps: policyApplication.steps,
    renewal: policyApplication.renewal,
    communications: {
      post: policyApplication.communications ? (policyApplication.communications.post || false) : false,
    },
    document_pack: policyApplication.documentPack,
    prevent_patch: policyApplication.preventPatch,
    medical_cover_for_covid: policyApplication.medicalCoverForCovid,
    claim_calculated: policyApplication.claimCalculated,
  };

  if (policyApplication.additionalRenewalLetter) {
    metadata.additional_renewal_letter = policyApplication.additionalRenewalLetter;
  }

  if (policyApplication.waitingListExclusion) {
    metadata.waiting_list_exclusion = policyApplication.waitingListExclusion;
  }

  if (policyApplication.waitingListExclusionNote) {
    metadata.waiting_list_exclusion_note = policyApplication.waitingListExclusionNote
  }

  if (policyApplication.upsellDate) {
    metadata.upsell_date = policyApplication.upsellDate
  }

  if (policyApplication.purchasedFromB2C) {
    metadata.purchased_from_b2c = policyApplication.purchasedFromB2C;
  }

  if (policyApplication.portal) {
    metadata.portal = policyApplication.portal;
  }

  if (policyApplication.purchaseChannel) {
    metadata.purchase_channel = policyApplication.purchaseChannel;
  }

  if (brokerId) {
    metadata.broker_id = brokerId;
  }

  if (policyApplication.campaign) {
    metadata.campaign = policyApplication.campaign;
  }

  if (policyApplication.cappingFactor) {
    metadata.capping_factor = policyApplication.cappingFactor;
  }

  if (policyApplication.priceIncrease) {
    metadata.price_increase = policyApplication.priceIncrease;
  }

  if (policyApplication.cappingFactorBreakdownKey) {
    metadata.capping_factor_breakdown_key = policyApplication.cappingFactorBreakdownKey;
  }

  if (policyApplication.fraudCheck) {
    metadata.fraud_check = policyApplication.fraudCheck;
  }

  if (policyApplication.upSellIncreaseRatio) {
    metadata.up_sell_increase_ratio = policyApplication.upSellIncreaseRatio;
  }

  if (policyApplication.cprResponse) {
    metadata.cpr_response = policyApplication.cprResponse;
  }

  if (policyApplication.quoteType === 'renewal' && policyApplication.nextTermRenewalStatus) {
    metadata.renewal_status = policyApplication.nextTermRenewalStatus;
  }

  if (policyApplication.renewalStatus) {
    metadata.renewal_status = policyApplication.renewalStatus;
  }

  if (policyApplication.signposted) {
    metadata.signposted = {
      medical_decline: policyApplication.signposted.medicalDecline,
      medical_load: policyApplication.signposted.medicalLoad,
    };
  }

  if (policyApplication.timeOfQuote) {
    metadata.time_of_quote = policyApplication.timeOfQuote;
  }

  if (policyApplication.mods) {
    metadata.mods = policyApplication.mods;
  }

  if (policyApplication.newBusinessLagDays) {
    metadata.new_business_lag_days = policyApplication.newBusinessLagDays;
  }

  if (policyApplication.renewalTerm) {
    metadata.renewal_term = policyApplication.renewalTerm;
  }

  if (policyApplication.previousQuoteDate) {
    metadata.previous_quote_date = policyApplication.previousQuoteDate;
  }

  if (policyApplication.previousStartDate) {
    metadata.previous_start_date = policyApplication.previousStartDate;
  }

  const meta = {
    customer: {
      create_account: policyApplication.createAccount ? policyApplication.createAccount : false,
      password: policyApplication.password,
      password_confirmation: policyApplication.passwordConfirmation,
    },
  };

  forEach(policyApplication.options, (value, key) => {
    if (value === 'true') {
      value = true;
    }

    if (value === 'false') {
      value = false;
    }

    metadata.options[key] = value;
  });

  // noinspection UnnecessaryLocalVariableJS
  const data: any = {
    type: 'application',
    attributes: {
      metadata,
      quote_reference: policyApplication.quoteReference,
      quote_type: policyApplication.quoteType,
      policy_number: policyApplication.renewingPolicyNumber,
    },
    meta,
    relationships: {},
  };

  if (policyApplication.schemeId && !policyApplication.calculateAllSchemes) {
    data.relationships.schemes = {
      data: [
        { id: policyApplication.schemeId },
      ],
    };
  }

  if (policyApplication.id) {
    data.id = policyApplication.id;
  }

  if (channelId) {
    data.relationships.channel = {
      data: {
        id: channelId,
      },
    };
  }

  if (productId) {
    data.relationships.product = {
      data: {
        id: productId,
      },
    }
  }

  if (brokerId) {
    data.relationships.broker = {
      data: {
        id: brokerId,
      },
    };
  }

  return { data, meta };
}
